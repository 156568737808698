<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add/Edit Visit/Note</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h4 v-if="churchName !== ``">
          {{ churchName }}
          <div class="change-link" @click="showChurchDropdown">
            {{ translations.tcChange }}
          </div>
        </h4>
        <h1 class="sm mb-4">{{ translations.tcAddEditChurchVisit }}</h1>
        <div class="body">
          <b-form>
            <b-form-group class="form-element">
              <span v-if="churchName === ``">
                <div class="font-style-2">{{ translations.tcSelectChurch }}</div>
                <div class="g-row">
                  <div class="g-col-1 d-flex">
                    <b-form-select
                      v-model="visit.nte_entity_key"
                      :options="selectChurch"
                      @change="handleChurchChange($event)"
                      class="form-control flex-1 g-select"
                    >
                    </b-form-select>
                  </div>
                  <div class="g-col-2"></div>
                </div>
              </span>
            </b-form-group>
          </b-form>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-3 bg-block mb-4">
        <div class="body">
          <b-form>
            <b-form-group class="form-element">
              <div class="font-style-2">{{ translations.tcSelectDate }}</div>
              <div class="date-select-1 mb-3">
                <b-form-datepicker
                  id="vistDate"
                  v-model="visit.nte_contact_date"
                  class="mb-2 contact_date"
                  :locale="prefCulture"
                  :label-help="translations.tcCalHelp"
                  :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcDatePickerPlaceholder"
                  :show-decade-nav="showDecadeNav"
                  :hide-header="hideHeader"
                >
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
              <div class="font-style-2">{{ translations.tcSelectEvent }}</div>
              <div class="g-row mb-0 mb-sm-3">
                <div class="g-col-1 d-flex mb-3 mb-sm-0">
                  <b-form-select
                    v-model="visit.nte_ctt_key"
                    :options="churchVisitContactTypesTranslated"
                    @change="handleEventChange($event)"
                    class="form-control col-left-input g-select flex-0 mr-3"
                  >
                  </b-form-select>
                </div>
              </div>
              <div v-if="!!this.visit.nte_ctt_key">
                <div class="font-style-2">{{ translations.tcContactedBy }}</div>
                <div class="g-row mb-0 mb-sm-3">
                  <div class="g-col-1 d-flex mb-3 mb-sm-0">
                    <b-form-select
                      v-model="visit.nte_contact_by_ind_key"
                      :options="filteredPresenterList"
                      class="form-control col-left-input g-select flex-0 mr-3"
                    >
                    </b-form-select>
                    <b-button
                      :name="`contactFindButton`"
                      variant="primary"
                      class="flex-1 btn-pl-xsm"
                      @click="showModal($event, 'personSearchModal')"
                    >
                      {{ translations.tcFind }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-form-group>
          </b-form>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcVisitDescription }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="visit.nte_note" class="w-100"></textarea>
        </div>
      </section>
      <section v-if="userSelectedChurchKey.length > 0" class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleSaveClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcSave }}
            </b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcCancel }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch
      @selectionMade="applyFoundPerson($event)"
      :auxiliary_search="this.isChosenContactTypeAuxiliary"
      :cmp_org_key="this.campKey"
    ></PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import { calendarMixin } from '@/mixins/calendarMixin'
import { numericMixin } from '@/mixins/numericMixin'

export default {
  name: 'add-church-visit',
  mixins: [numericMixin, translationMixin, calendarMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      filteredPresenterList: [],
      findButton: '',
      isSelectedContactTypeAuxiliary: null,
      savedContact: [],
      selectChurch: [],
      searchedContact: [],
      foundContactSearches: [],
      statusKey: null,
      visit: {
        nte_entity_key: null,
        nte_contact_by_ind_key: null,
        nte_subject: null,
        nte_note: null,
        nte_contact_date: this.formatForDatePicker(new Date()),
        nte_ctt_key: null,
        nte_nag_key: null,
        nte_add_user: null,
        nte_delete_flag: 0,
        nte_key: null
      },
      churchVisitContactTypesTranslated: {}
    }
  },
  methods: {
    ...mapActions({
      addUpdateChurchVisitNote: 'churchMinistry/addUpdateChurchVisitNote',
      clearSingleVisit: 'churchMinistry/clearSingleVisit',
      getAllPresentersByCamp: 'churchMinistry/getAllPresentersByCamp',
      getChurchVisitContactTypes: 'churchMinistry/getChurchVisitContactTypes',
      getSingleVisit: 'churchMinistry/getSingleVisit',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey'
    }),
    async handleChurchChange(evt) {
      try {
        await Promise.all([this.setLoadingStatus(true), await this.setSelectedChurchKey(evt)])
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleCancelClick() {
      let linkBack = this.userRouterBackLink
      this.setRouterBackLink('')
      if (linkBack.length > 0) {
        this.$router.push({ name: linkBack })
      } else {
        this.$router.push('/programs/cm/church-visits-card')
      }
    },
    async handleEventChange(evt) {
      let selectedContactType = this.churchVisitContactTypes.find(k => k.value === evt)
      this.visit.nte_subject = selectedContactType.text

      // If the chosen contact type is auxiliary and the previously selected contact type
      // was not, or vice versa, clear the contacted by field and refresh the search with
      // the correct presenter list.
      if (
        (selectedContactType.is_auxiliary_type && !this.isSelectedContactTypeAuxiliary) ||
        (!selectedContactType.is_auxiliary_type && this.isSelectedContactTypeAuxiliary)
      ) {
        this.setPresenterList()

        if (
          !!this.visit.nte_contact_by_ind_key &&
          !this.filteredPresenterList.find(k => k.value === this.visit.nte_contact_by_ind_key)
        ) {
          this.visit.nte_contact_by_ind_key = null
        }
      }

      // Update the state of selected contact type to the currently chosen type.
      this.isSelectedContactTypeAuxiliary = selectedContactType.is_auxiliary_type
    },
    async handleSaveClick() {
      if (
        this.visit.nte_entity_key === null ||
        this.visit.nte_contact_by_ind_key === null ||
        this.visit.nte_note === null ||
        this.visit.nte_contact_date === null ||
        this.visit.nte_ctt_key === null
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAllDataFieldsRequired,
          confirmButtonText: this.translations.tcOk
        }).then(result => {
          return
        })
      } else {
        try {
          this.visit.nte_add_user = this.userId
          let result = true
          await Promise.all([
            this.setLoadingStatus(true),
            (result = await this.addUpdateChurchVisitNote(this.visit))
          ]).then(() => {
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.translations.tcVisitSaved : this.translations.tcErrorDuringSave,
              confirmButtonText: this.translations.tcOk
            }).then(result => {
              if (result.value) {
                this.setRouterBackLink('')
                this.clearSingleVisit()
                this.setSelectedVisitKey(null)
                this.handleCancelClick()
              }
            })
          })
        } catch (e) {
          console.error(e)
        } finally {
          this.setLoadingStatus(false)
        }
      }
    },
    async applyFoundPerson(evt) {
      if (!this.foundContactSearches.find(k => k.value === evt.ind_key)) {
        this.foundContactSearches.push({
          value: evt.ind_key,
          text: evt.name_text,
          is_auxiliary_result: evt.auxiliary_member
        })
      }

      this.visit.nte_contact_by_ind_key = evt.ind_key
      this.setPresenterList()
    },
    setPresenterList() {
      this.searchedContact = this.foundContactSearches.filter(
        rec => !this.isChosenContactTypeAuxiliary || rec.is_auxiliary_result
      )

      if (this.isChosenContactTypeAuxiliary) {
        this.filteredPresenterList = [...this.auxiliaryPresenterList, ...this.searchedContact]
      } else {
        this.filteredPresenterList = [...this.presenterList, ...this.searchedContact]
      }

      // The saved contact will only be populated if an existing visit is being edited,
      // and the current contacted by member is not in either presenter list set.
      if (!!this.savedContact && this.savedContact.length > 0) {
        this.filteredPresenterList.push(...this.savedContact)
      }
    },
    async showChurchDropdown() {
      this.newPresentations = []
      this.availableServices = []
      this.selectedChurchKey = null
      await this.setSelectedChurchKey('')
    },
    async assembleSingleVisit() {
      await Promise.all([
        await this.getSingleVisit(this.selectedVisitKey),
        await this.setSelectedChurchKey(this.singleVisit.nte_entity_key),
        await (this.selectedChurchKey = this.singleVisit.nte_entity_key),
        await this.handleChurchChange(this.userSelectedChurchKey)
      ]).then(() => {
        this.visit = {
          nte_entity_key: this.singleVisit.nte_entity_key,
          nte_contact_by_ind_key: this.singleVisit.nte_contact_by_ind_key,
          nte_subject: this.singleVisit.nte_subject,
          nte_note: this.singleVisit.nte_note,
          nte_contact_date: this.singleVisit.nte_contact_date,
          nte_ctt_key: this.singleVisit.nte_ctt_key,
          nte_nag_key: this.singleVisit.nte_nag_key,
          nte_add_user: this.singleVisit.nte_change_user,
          nte_delete_flag: this.singleVisit.nte_delete_flag,
          nte_key: this.singleVisit.nte_key
        }
      })
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.getTranslations(),
          await this.getComponentTranslations('common.church-visit-types'),
          await this.clearSingleVisit(),
          await this.loadChurches(),
          await this.getChurchVisitContactTypes(),
          await this.getAllPresentersByCamp()
        ]).then(results => {
          this.stripReadableText(results[1])
          this.translateDropdownOptions([
            {
              node: 'church-visit-types',
              store: 'churchVisitContactTypes',
              disabledPlaceholder: this.translations.tcDropdownPlaceholder
                ? this.translations.tcDropdownPlaceholder
                : 'Event Type'
            }
          ])

          this.selectChurch = [...this.selectChurch, ...this.churchesForSelection]
          this.setPresenterList()
        })
        this.visit.nte_entity_key = !!this.userSelectedChurchKey ? this.userSelectedChurchKey : null
        // decide here: add or edit
        if (this.selectedVisitKey !== null && this.isGuid(this.selectedVisitKey)) {
          await this.assembleSingleVisit()

          // current presenter may be in another camp
          // check the list and if they are not there, add them as searched
          const { nte_contact_by_ind_key, name } = this.singleVisit
          const presenterInList = this.presenterList.some(pres => {
            return pres.value === nte_contact_by_ind_key
          })

          this.isSelectedContactTypeAuxiliary = this.isChosenContactTypeAuxiliary

          let auxiliaryPresenterInList = false
          if (this.isChosenContactTypeAuxiliary) {
            auxiliaryPresenterInList = this.auxiliaryPresenterList.some(pres => {
              return pres.value === nte_contact_by_ind_key
            })
          }

          if (!presenterInList && !auxiliaryPresenterInList) {
            const currentPresenterToAdd = { text: name, value: nte_contact_by_ind_key }
            this.savedContact.unshift(currentPresenterToAdd)
          }

          this.setPresenterList()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    }
  },
  async created() {
    await this.page_load()
  },
  computed: {
    ...mapGetters({
      auxiliaryPresenterList: 'churchMinistry/allAuxiliaryPresentersList',
      campKey: 'user/userCampKey',
      churches: 'churchMinistry/churches',
      churchesForSelection: 'churchMinistry/churchesForSelection',
      churchVisitContactTypes: 'churchMinistry/churchVisitContactTypes',
      memberTypes: 'membership/membertype',
      prefCulture: 'user/userPreferredCulture',
      presenterList: 'churchMinistry/allPresentersList',
      selectedVisitKey: 'churchMinistry/selected_visit_key',
      singleVisit: 'churchMinistry/singleVisit',
      userId: 'user/userId',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      userRouterBackLink: 'user/userRouterBackLink'
    }),
    isChosenContactTypeAuxiliary() {
      let result = !!this.visit.nte_ctt_key

      if (result) {
        let matchedContactType = this.churchVisitContactTypes.find(k => k.value === this.visit.nte_ctt_key)

        if (!!matchedContactType) {
          result = matchedContactType.is_auxiliary_type
        } else {
          // If the currently selected contact type does not exist in the allowed contact types,
          // then the visit type is invalid. Clear the value to enforce remediation.
          this.visit.nte_ctt_key = null
        }
      }

      return result
    },
    churchName() {
      let cName = ''
      if (!!this.userSelectedChurchKey && this.churches.length > 0) {
        cName = this.churches.find(fn => fn.chu_org_key === this.userSelectedChurchKey).church_name
      }
      return cName
    }
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow
  }
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.g-row {
  margin-bottom: 20px;
}

.err_message {
  color: #dd0000;
  font-weight: 600;
}

.form_label {
  font-size: 14px !important;
  font-weight: 600;
}

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 45%;

  @include breakpoint(sm) {
    width: 100%;
  }

  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
